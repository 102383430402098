import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useHistory } from "react-router-dom";

import styles from "./ThankYouPage.module.scss";
import OnboardingFormSubmit from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import { conditions } from "../../utils/conditionFocus";
import { DotsOfStep } from "../../components/DotsOfStep/DotsOfStep";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";

import { ReactComponent as Logo } from "../../assets/images/svg/logo.svg";
import Welcome from "../../assets/images/thankyou/Welcome.png";
import WelcomeMobile from "../../assets/images/thankyou/WelcomeMobile.png";
import Experts from "../../assets/images/thankyou/Experts.png";
import ExpertsMobile from "../../assets/images/thankyou/ExpertsMobile.png";
import CarePlan from "../../assets/images/thankyou/CarePlan.png";
import CarePlanMobile from "../../assets/images/thankyou/CarePlanMobile.png";
import Conceirge from "../../assets/images/thankyou/Concierge.png";
import ConceirgeMobile from "../../assets/images/thankyou/ConciergeMobile.png";
import Trends from "../../assets/images/thankyou/Trends.png";
import TrendsMobile from "../../assets/images/thankyou/TrendsMobile.png";

export const ThankYouPage = ({ user }: any) => {
  const [step, setStep] = useState(1);
  const [userName, setUserName] = useState("");
  const [conditionFocus, setConditionFocus] = useState<string[]>([]);

  const history = useHistory();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const isHeadache = conditionFocus.includes(conditions.HEADACHE_MIGRAINE);

  let firestore = firebase.firestore;

  const stepContent: Record<
    number,
    { image: React.ReactNode; heading: string; subheading: string }
  > = {
    1: {
      image: <img src={isResponsiveView ? WelcomeMobile : Welcome} alt="Welcome" />,
      heading: "Welcome to Neura Health,",
      subheading:
        "Our mission is to improve access to and quality of care for neurological conditions",
    },
    2: {
      image: <img src={isResponsiveView ? ExpertsMobile : Experts} alt="Experts" />,
      heading: "Meet with experts to get the full picture",
      subheading: "Book virtual visits with licensed neurologists and care coaches.",
    },
    3: {
      image: <img src={isResponsiveView ? CarePlanMobile : CarePlan} alt="Care Plan" />,

      heading: "Get a personalized care plan to take control of your health",
      subheading: "Navigate the path to better health with expert coaching and support.",
    },
    4: {
      image: <img src={isResponsiveView ? ConceirgeMobile : Conceirge} alt="Conceirge" />,
      heading: "Care concierge available by chat 24/7",
      subheading:
        "From referrals to prior authorizations, we’ll help get it sorted quickly and easily.",
    },
    5: {
      image: <img src={isResponsiveView ? TrendsMobile : Trends} alt="Trends" />,
      heading: "Discover trends by tracking attacks",
      subheading: "Easily record attacks to better understand triggers and symptoms.",
    },
  };

  const isLastStep = (isHeadache && step === 5) || (!isHeadache && step === 4);

  const handleNextStep = () => {
    if (isLastStep) {
      history.push({
        pathname: "/home",
        search: "?isWelcomeFlow=true",
      });
    } else {
      setStep(step + 1);
    }
  };

  useEffect((): any => {
    let subscription: Promise<void>;
    if (user.uid) {
      subscription = firestore()
        .collection("patients")
        .doc(user.uid)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data();
          setUserName(`${data?.firstName}`);
          if (data?.conditionFocus) setConditionFocus(data.conditionFocus);
          else setConditionFocus([]);
        });
    }

    return () => subscription;
  }, [firestore, user.uid]);

  const content = stepContent[step];
  return (
    <div className={styles.root}>
      <Logo />
      {content?.image}
      <div>
        <h4 className={styles.heading}>{content?.heading}</h4>
        {step === 1 && <h4 className={styles.heading}>{userName}</h4>}
      </div>
      <p className={styles.bodyOne}>{content?.subheading}</p>
      <OnboardingFormSubmit
        title={step === 1 ? "Get started" : isLastStep ? "Book your first visit" : "Next"}
        onClick={handleNextStep}
        className={styles.nextButton}
      />
      <DotsOfStep isFiveSteps={isHeadache} step={step} />
      <div className={styles.backgroundCircleTop} />
    </div>
  );
};
