import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import Button from "../../components/Button/Button";
import OnboardingFormHeading from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import styles from "./ConfirmAppointmentPage.module.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";

import { ReactComponent as IconSpin } from "../../assets/icons/spinner.svg";
import { useEffect, useMemo } from "react";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";
import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";

type queryType = { patientID: string; appointmentID: string };

export const ConfirmAppointmentPage = () => {
  const { search } = useLocation();
  const { patientID, appointmentID } = qs.parse(search) as queryType;
  const functions = new FirebaseApi().firebaseFunctions();

  const dataMissing = !patientID || !appointmentID;

  const { data, isLoading } = useQuery({
    queryKey: ["appointment", patientID, appointmentID],
    queryFn: async () =>
      await functions.getAppointmentForConfirmation({ patientID, appointmentID }),
    enabled: !dataMissing,
  });

  const {
    mutate: confirm,
    isSuccess,
    isLoading: isConfirmLoading,
  } = useMutation({
    mutationKey: ["confirm-appointment", patientID, appointmentID],
    mutationFn: () => functions.confirmAppointment({ patientID, appointmentID }),
  });

  const formattedDate = useMemo(() => {
    if (!data?.appointmentTime) return "N/A";
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const appointmentTime = utcToZonedTime(data.appointmentTime * 1000, timeZone);
    return formatTz(appointmentTime, "MMM d, yyyy 'at' h:mm a", { timeZone });
  }, [data?.appointmentTime]);

  const description = useMemo(() => {
    if (data) {
      return (
        <h2 className={styles.description}>
          {data.appointmentReason} with {data.providerName} on {formattedDate}
        </h2>
      );
    }
  }, [data, formattedDate]);

  useEffect(() => {
    if (dataMissing || (!data && !isLoading)) {
      window.location.href = "https://www.neurahealth.co/404";
    }
  }, [data, dataMissing, isLoading]);

  return (
    <OnboardingLayout visibleLogo>
      <div className={styles.page}>
        {isSuccess ? (
          <>
            <OnboardingFormHeading title="Your appointment is confirmed" />
            {description}
          </>
        ) : isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <OnboardingFormHeading title="Confirm Appointment" />
            {description}
            <Button
              shape="squared"
              className={styles.button}
              onClick={confirm}
              disabled={isConfirmLoading}
            >
              {isConfirmLoading ? <IconSpin /> : "Confirm"}
            </Button>
            <Button
              tag="a"
              shape="squared"
              variant="secondary"
              className={styles.button}
              to={data?.mgmtLink}
              target="_blank"
              search=""
              disabled={isConfirmLoading}
            >
              Reschedule / Cancel
            </Button>
          </>
        )}
      </div>
    </OnboardingLayout>
  );
};
