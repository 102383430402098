import OnboardingFormTextInput from "../../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormTextInputDate from "../../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInputDate";
import OnboardingFormSubmit from "../OnboardingFormSubmit/OnboardingFormSubmit";
import Grid, { GridElem } from "../../../Grid/Grid";
import styles from "./PersonalDataForm.module.scss";
import { IOnboardingFormSection } from "../../OnboardingFormSection.interface";

import FirebaseApi from "../../../../api/FirebaseApi/FirebaseApi";
import sessionCheck from "../../../../helpers/sessionCheck";
import { ReactComponent as IconAlert } from "../../../../assets/icons/alert.svg";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { parse, isValid, differenceInYears, format, compareAsc } from "date-fns";
import cn from "classnames";
import { Field, Form, Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import firebase from "firebase/compat/app";
import OnboardingFormHeading from "../OnboardingFormHeading/OnboardingFormHeading";

interface IProps extends IOnboardingFormSection {
  children?: React.ReactNode;
  handleTracking?: (values: FormValues) => void;
  withActivateMember?: boolean;
  isPersonalDataStep?: boolean;
}

export type FormValues = {
  firstName: string;
  lastName: string;
  birthDate: string;
  b2bMemberID?: string;
};

export const PersonalDataForm = ({
  children,
  formData,
  setFormData,
  patientData,
  partnerData,
  partner,
  uid,
  userEmail,
  setLoginMessage,
  step,
  setFirstStep,
  setNextStep,
  handleTracking,
  withActivateMember,
  isPersonalDataStep,
}: IProps) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: formData?.firstName || patientData?.firstName || "",
    lastName: formData?.lastName || patientData?.lastName || "",
    birthDate: formData?.birthDate || patientData?.birthDate || "",
    b2bMemberID: formData?.b2bMemberID || patientData?.b2bMemberID || "",
  });

  const functions = new FirebaseApi().firebaseFunctions();
  const history = useHistory();
  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const requiredMemberId: any = partnerData?.onboardingRequireMemberID
    ? { b2bMemberID: Yup.string().required("Member ID is required.") }
    : {};

  const checkDuplicateAccounts = async (patientID: string) => {
    const functions = new FirebaseApi().firebaseFunctions();
    setLoader(true);
    try {
      const response = await functions.checkForDuplicateAccounts({ patientID });
      setLoader(false);
      return response.data;
    } catch (error) {
      setError("Something went wrong. Please try again later.");
      setLoader(false);
    }
  };

  const activateMember = async () => {
    try {
      if (partner) {
        if (formData?.uid || patientData.uid) {
          setLoader(true);
          const patientID = formData?.uid || patientData.uid;
          const data = await functions.activateMember({ partner, patientID });

          if (!data.success) {
            setLoader(false);
            setError(data.reason);
            return;
          }
          setLoader(false);
          setNextStep();
        }
      }
    } catch (error) {
      setError("Something went wrong. Please try again later.");
    }
  };

  let firestore = firebase.firestore;

  const updatePatientData = async ({ firstName, lastName, birthDate, b2bMemberID }: FormValues) => {
    if (formData?.uid || uid || patientData?.uid) {
      try {
        await firestore()
          .collection("patients")
          .doc(formData?.uid || uid || patientData?.uid)
          .update({ firstName, lastName, birthDate, b2bMemberID });
      } catch (error) {
        setError("Something went wrong. Please try again later.");
      }
    }
  };

  const onSubmit = async (values: FormValues) => {
    const session = await sessionCheck(step, setFirstStep, setLoginMessage, history);

    if (!session) {
      return;
    }

    setFormData({
      ...formData,
      firstName: values?.firstName.trim(),
      lastName: values?.lastName.trim(),
      birthDate: values?.birthDate.trim(),
      b2bMemberID: values?.b2bMemberID?.trim(),
    });

    const firestore = new FirebaseApi().firebaseFirestore();
    const birthDate = values?.birthDate || formData?.birthDate || patientData.birthDate;
    let dobHS = new Date(birthDate);
    dobHS.setUTCHours(0, 0, 0, 0);

    const payload = {
      uid: formData?.uid || uid || patientData?.uid,
      isTestCustomer: process.env.REACT_APP_ENV !== "production",
      firstName: values?.firstName.trim() || formData?.firstName.trim(),
      lastName: values?.lastName.trim() || formData?.lastName.trim(),
      birthDate: format(new Date(birthDate), "MM/dd/yyyy"),
      b2bMemberID: values?.b2bMemberID?.trim() || formData?.b2bMemberID?.trim() || "",
    };
    if (isPersonalDataStep) {
      try {
        await firestore.updatePatient({ data: payload });
      } catch (error) {
        setError("Something went wrong. Please try again later.");
      }
    } else {
      updatePatientData(values);
    }

    const isDuplicateAccount = await checkDuplicateAccounts(payload.uid);

    if (!isDuplicateAccount.success) {
      setError(isDuplicateAccount.reason);
      return;
    }

    if (isPersonalDataStep) setNextStep();

    try {
      handleTracking && handleTracking(values);

      const functions = new FirebaseApi().firebaseFunctions();

      functions.updateHubspotContact("firstname", {
        email: formData?.email || userEmail,
        eventValue: values.firstName,
      });

      functions.updateHubspotContact("dob", {
        email: formData?.email || userEmail,
        eventValue: dobHS.toISOString(),
      });

      functions.updateHubspotContact("lastname", {
        email: formData?.email || userEmail,
        eventValue: values.lastName,
      });

      if (withActivateMember) {
        activateMember();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      birthDate: formValues?.birthDate,
      b2bMemberID: formValues?.b2bMemberID ?? "",
    });
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        firstName: formData?.firstName || patientData?.firstName || "",
        lastName: formData?.lastName || patientData?.lastName || "",
        birthDate: formData?.birthDate || patientData?.birthDate || "",
        b2bMemberID: formData?.b2bMemberID || patientData?.b2bMemberID || "",
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        ...requiredMemberId,
        firstName: Yup.string().min(2).required("First Name is required."),
        lastName: Yup.string().min(2).required("Last Name is required."),
        birthDate: Yup.string()
          .test("validDate", "Date must be in proper format (mm/dd/yyyy)", (value) => {
            if (!value) return false;
            return isValid(parse(value, "MM/dd/yyyy", new Date())) && value.length === 10;
          })
          .test("14YearsOld", "Neura serves patients 14 and older", (value) => {
            if (!value) return false;
            return differenceInYears(new Date(), new Date(value)) >= 14;
          })
          .test("pastDate", "Date must be newer than 01/01/1900", (value) => {
            if (!value) return false;
            return compareAsc(new Date(value), new Date("01/01/1900")) === 1;
          })
          .required("Birthday is required.")
          .typeError("Date must be in proper format (mm/dd/yyyy)"),
      })}
      on
      validateOnChange
    >
      {({ isValid }) => (
        <Form
          className={cn(
            withActivateMember && styles.formModal,
            isPersonalDataStep && styles.personalDataStep,
            styles.form
          )}
          id="name_birth_date"
          onChange={(e: React.ChangeEvent<HTMLFormElement>) => onChange(e)}
        >
          <div className="d-flex flex-column align-items-lg-end mb-xlarge mb-3">
            {children && children}

            <Grid
              className={cn(
                withActivateMember && styles.gridWrapperActivateMember,
                styles.gridWrapper
              )}
            >
              <GridElem>
                <OnboardingFormHeading className={styles.heading} title="Who is getting care?" />
              </GridElem>
              <GridElem>
                <Field
                  label="Patient's legal first name"
                  name="firstName"
                  component={OnboardingFormTextInput}
                />
              </GridElem>

              <GridElem>
                <Field
                  label="Patient's legal last name"
                  name="lastName"
                  component={OnboardingFormTextInput}
                />
              </GridElem>

              <GridElem size={6}>
                <Field
                  label="Patient's birthday"
                  name="birthDate"
                  component={OnboardingFormTextInputDate}
                  placeholder="mm/dd/yyyy"
                />
              </GridElem>
              {partnerData?.onboardingRequireMemberID && (
                <GridElem>
                  <Field
                    label="Member ID"
                    name="b2bMemberID"
                    component={OnboardingFormTextInput}
                    placeholder="Member ID"
                  />
                </GridElem>
              )}
            </Grid>
          </div>

          <div className={cn("d-flex justify-content-center align-items-center flex-column")}>
            {error && (
              <p
                className={cn(
                  styles.errorMessage,
                  "d-flex justify-content-center align-items-left w-100"
                )}
              >
                <span className={styles.iconContainer}>
                  <IconAlert className={styles.icon} />
                </span>

                <p style={{ width: "100%" }}>{error}</p>
              </p>
            )}

            <OnboardingFormSubmit
              title={isPersonalDataStep ? "Next" : "Join Neura"}
              isSubmitting={loader}
              disabled={!isValid || loader}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
