import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { format } from "date-fns";
import amplitude from "amplitude-js";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import Drawer from "react-modern-drawer";
import OnboardingFormHeading from "../OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import Grid, { GridElem } from "../../Grid/Grid";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import OnboardingFormAgreement from "../OnboardingFormComponents/OnboardingFormAgreement/OnboardingFormAgreement";
import OnboardingFormChoosePlan from "../OnboardingFormComponents/OnboardingFormChoosePlan/OnboardingFormChoosePlan";
import sessionCheck from "../../../helpers/sessionCheck";
import DescriptionInfo from "../../DescriptionInfo/DescriptionInfo";
import { ReactComponent as BackgroundIcon } from "../../../assets/images/svg/onboarding-phone-background-icon.svg";
import { ReactComponent as Cancel } from "../../../assets/images/svg/cancel.svg";
import NumberOfStep from "../../NumberOfStep/NumberOfStep";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import styles from "./OnboardingFormSectionMedicalPersonal.module.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import { conditions } from "../../../utils/conditionFocus";
import { IProvider } from "../../../types/provider";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import { IPatient } from "../../../types/patient";
import { partnersName } from "../../../utils/partnerList";
import { CARETEAM_ROLE_COACH } from "../../../helpers/constants";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import ModalLayout from "../../ModalLayout/ModalLayout";
import { ActivateMemberFailureModal } from "../OnboardingFormComponents/ActivateMemberFailureModal/ActivateMemberFailureModal";
import { useGATrackingForSpecificPartner } from "../../../hooks/useGATrackingForSpecificPartner";
type FormValuesT = {
  planName: string;
  consentedToSMSMarketing: boolean;
};

const headacheProvider: string = "mz2yHuSQMrIOe7EvfpRm"; // Tom
const sleepProvider: string = "sx6q01Ij1uZ8WfQvED5ybD8JEmt1"; // Sam

const OnboardingFormSectionMedicalPersonal = ({
  uid,
  formData,
  setFirstStep,
  setNextStep,
  setFormData,
  setLoginMessage,
  patientData,
  step,
  setLoading,
  partner,
  coupon,
}: IOnboardingFormSection) => {
  const [provider, setProvider] = useState<IProvider>();
  const [activateErrorMsg, setActivateErrorMsg] = useState("");
  const [loadingActiveMember, setLoadingActiveMember] = useState(false);
  const [isReferralDiscount, setIsReferralDiscount] = useState(false);
  const [careTeam, setCareTeam] = useState<string[]>([]);
  const [loadingCareTeamList, setLoadingCareTeamList] = useState(true);
  const [coachProvider, setCoachProvider] = useState<string>("");
  const [isOpenActivateMemberModal, setIsOpenActivateMemberModal] = useState(false);

  const partnerCoach = partner === partnersName.CBTI;

  const firestore = new FirebaseApi().firebaseFirestore();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const { runTracking } = useGATrackingForSpecificPartner();

  const conditionFocus = formData?.conditionFocus ??
    patientData.conditionFocus ?? [conditions.HEADACHE_MIGRAINE];
  const isHeadacheConditionFocus = conditionFocus.includes(conditions.HEADACHE_MIGRAINE);

  const fetchPatientData = () => {
    if (!uid) return;
    setLoadingCareTeamList(true);
    firestore
      .getPatientData(uid)
      .then((data: IPatient) => {
        const careTeam = data.careTeam;
        careTeam.forEach(async (providerId, index) => {
          const provider = await firestore.fetchProviderById(providerId);
          if (provider.role === CARETEAM_ROLE_COACH) {
            setCoachProvider(careTeam[index]);
          }
        });
        setCareTeam(careTeam);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingCareTeamList(false));
  };

  const fetchProvider = async () => {
    let providerdId = "";
    if (partnerCoach && coachProvider) {
      providerdId = coachProvider;
    } else {
      providerdId =
        careTeam.length > 0
          ? careTeam[0]
          : isHeadacheConditionFocus
          ? headacheProvider
          : sleepProvider;
    }
    const provider = await firestore.fetchProviderById(providerdId);
    setProvider(provider);
    setLoading(false);
  };

  const handleClose = () => {
    setIsOpenActivateMemberModal(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  useEffect(() => {
    if (!provider) {
      setLoading(true);
      if (!loadingCareTeamList) {
        fetchProvider();
      }
    }
  }, [careTeam, loadingCareTeamList, coachProvider]);

  const history = useHistory();

  const onSubmit = async (values: FormValuesT) => {
    setLoadingActiveMember(true);
    try {
      const session = await sessionCheck(step, setFirstStep, setLoginMessage, history);

      if (!session) {
        return;
      }

      const firestore = new FirebaseApi().firebaseFirestore();
      const functions = new FirebaseApi().firebaseFunctions();

      await firestore.updatePatient({
        data: {
          uid: uid || patientData?.uid,
          consentFormsSigned: [
            {
              dateSigned: format(new Date(), "MM/dd/yyyy"),
              versionSigned: "1",
            },
          ],
          planName: values?.planName,
          consentedToSMSMarketing: values.consentedToSMSMarketing,
        },
      });

      setFormData({
        ...formData,
        planName: values?.planName,
        consentedToSMSMarketing: values.consentedToSMSMarketing,
      });

      if (partner) {
        if (formData?.uid || patientData.uid || uid) {
          const data = await functions.activateMember({
            partner, // "test_internal" for dev
            patientID: formData?.uid || patientData.uid,
          });

          if (data.success === false && data.reason.length > 0) {
            setActivateErrorMsg(data.reason);
            setIsOpenActivateMemberModal(true);
            document.body.style.overflow = "hidden";
            return;
          }
        }
      }

      await functions.updateHubspotContact("consented_to_sms_marketing", {
        email: formData?.email || patientData?.email,
        eventValue: values.consentedToSMSMarketing,
      });

      setNextStep();

      amplitude.getInstance().logEvent("onboarding_v2_consent_click");

      // This event is for one specific partner, Theranica
      runTracking(partnersName.THERANICA, "neura_consent-accepted");

      isNotExcludedFromTracking(formData?.email || patientData?.email, () => {
        // @ts-ignore
        window?.dataLayer.push({
          event: "onboarding_v2_consent_click",
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const generateProviderText = () => {
    if (partnerCoach) {
      return (
        <>
          Hi {patientData?.firstName || formData?.firstName}, <br />
          I'm {provider?.name}
          <br />
          and I am a care coach at Neura.
          <br />
          As a member, you can book a video
          <br />
          visit with me to start your CBT-I
          <br />
          program. Your care team
          <br />
          collaborates to provide you with
          <br />
          best-in-class care.
        </>
      );
    }

    return (
      <>
        Hi {patientData?.firstName || formData?.firstName}, <br />
        I'm {provider?.name}
        <br />
        and I am a neurology specialist at Neura.
        <br />
        As a member, you can book a video
        <br />
        visit with me or a fellow clinician within
        <br />
        just a few days. Your care team
        <br />
        collaborates to provide you with
        <br />
        best-in-class care.
      </>
    );
  };

  useEffect(() => {
    if (coupon?.id) {
      setIsReferralDiscount(true);
    }
    return () => setIsReferralDiscount(false);
  }, [coupon?.id]);

  useEffect(() => {
    setLoadingActiveMember(false);
    if (!isOpenActivateMemberModal) document.body.style.overflow = "unset";
  }, [isOpenActivateMemberModal]);

  return (
    <>
      <Formik
        initialValues={{
          telemedicineDelivery: formData?.telemedicineDelivery || false,
          hipaa: formData?.hipaa || false,
          planName: formData?.planName || patientData?.planName || "quarterly",
          consentedToSMSMarketing: formData?.consentedToSMSMarketing || true,
        }}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
        validationSchema={Yup.object({
          telemedicineDelivery: Yup.boolean().oneOf([true], "This field is required."),
          hipaa: Yup.boolean().oneOf([true], "This field is required."),
          planName: Yup.string()
            .required()
            .oneOf(["monthly", "quarterly", "annual"], "Please choose a plan."),
          consentedToSMSMarketing: Yup.boolean().oneOf(
            [true, false],
            "Please let us know your choice"
          ),
        })}
        validateOnChange
        validateOnMount
      >
        {({ isValid, isSubmitting }) => (
          <Form className="d-flex flex-column h-100" id="phone_number_privacy_policies">
            <div className="d-flex justify-content-center">
              <OnboardingFormHeading
                className={styles.header}
                title="Dedicated care and support, just a step away"
              />
            </div>

            <GridElem className={cn(smallScreen ? styles.infoBoxMobile : styles.infoBox)}>
              <DescriptionInfo smallScreen={isResponsiveView} text={generateProviderText()} />
            </GridElem>

            <LazyLoadImage
              wrapperClassName={styles.photo}
              alt="hero"
              effect="blur"
              // @ts-ignore
              src={provider?.photoUrl}
            />

            <BackgroundIcon className={styles.backgroundIcon} />

            <BackgroundIcon className={styles.backgroundIcon2} />

            {patientData?.lastSubscriptionCancellationDate || partner ? (
              <div />
            ) : (
              <>
                <div className={styles.formHeader}>
                  <p className={styles.formHeaderL1}>Start today and get the</p>
                  <p className={styles.formHeaderL2}>First week for $1</p>
                </div>
                <Grid className={styles.planDetails} rowGap={0}>
                  <GridElem className={cn(styles.planPickerHeader, "text-center")}>
                    <p>Select your plan</p>

                    <div className={styles.planSwitchText}>
                      Switch plans any time after signing up
                    </div>
                  </GridElem>

                  <GridElem className={styles.planRow}>
                    <Field
                      name="planName"
                      type="radio"
                      value="monthly"
                      component={OnboardingFormChoosePlan}
                      validateOnChange
                    >
                      <div className={styles.planName}>Monthly plan</div>
                      <div className={styles.planNameDetail}>$39/month, cancel anytime</div>
                    </Field>
                  </GridElem>

                  <GridElem className={styles.planRow}>
                    <Field
                      name="planName"
                      type="radio"
                      value="quarterly"
                      component={OnboardingFormChoosePlan}
                      validateOnChange
                    >
                      <div className={styles.planName}>
                        Quarterly plan{" "}
                        <span className={styles.mostPopularPlanName}>Most Popular</span>
                      </div>
                      <div className={styles.planNameDetail}>
                        $35/month ($105 quarterly)
                        <span className={styles.planDiscount}>Save 10%</span>
                      </div>
                    </Field>
                  </GridElem>

                  <GridElem className={styles.planRow}>
                    <Field
                      name="planName"
                      type="radio"
                      value="annual"
                      component={OnboardingFormChoosePlan}
                    >
                      <div className={styles.planName}>Annual plan</div>
                      <div className={styles.planNameDetail}>
                        $29/month ($348 annually)
                        <span className={styles.planDiscount}>Save 26%</span>
                      </div>
                    </Field>
                  </GridElem>
                </Grid>
                {isReferralDiscount && (
                  <div className={styles.promoCard}>
                    <p>
                      $15 off your first full monthly, quarterly or annual membership payment will
                      be automatically applied. It is not yet reflected above.
                    </p>
                  </div>
                )}
              </>
            )}

            <Grid rowGap={0}>
              <GridElem className={styles.checkboxWrapper}>
                <Field
                  name="telemedicineDelivery"
                  component={OnboardingFormAgreement}
                  validateOnChange
                >
                  I consent to &nbsp;
                  <a
                    href={
                      partner === partnersName.EMORY
                        ? "https://app.neurahealth.co/emory_consent"
                        : "https://neurahealth.co/consent-for-telemedicine"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Care Delivery via Telemedicine&#46;
                  </a>
                </Field>
              </GridElem>

              <GridElem>
                <Field name="hipaa" component={OnboardingFormAgreement} validateOnChange>
                  I confirm I have received the notice of &nbsp;
                  <a
                    href={
                      partner === partnersName.EMORY
                        ? "https://app.neurahealth.co/emory_npp"
                        : "https://neurahealth.co/hipaa-privacy-practices"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    HIPAA Privacy Practices&#46;
                  </a>
                </Field>
              </GridElem>

              <GridElem>
                <Field
                  name="consentedToSMSMarketing"
                  component={OnboardingFormAgreement}
                  validateOnChange
                >
                  I consent to receiving SMS marketing messages from Neura Health. Reply STOP to
                  cancel, HELP for help. Msg & data rates may apply.
                </Field>
              </GridElem>
            </Grid>
            {activateErrorMsg && <p className={styles.errorMessage}>{activateErrorMsg}</p>}
            <div className="d-flex justify-content-center mt-small">
              <OnboardingFormSubmit
                title={partner ? "Join Neura" : "Next"}
                disabled={!isValid || loadingActiveMember || isSubmitting}
                isSubmitting={loadingActiveMember || isSubmitting}
              />
            </div>

            <NumberOfStep step={step} />
          </Form>
        )}
      </Formik>

      {isResponsiveView && isOpenActivateMemberModal && (
        <Drawer
          size="90%"
          open={isOpenActivateMemberModal}
          onClose={handleClose}
          direction="bottom"
          className={styles.drawer}
        >
          <button className={styles.close} onClick={handleClose}>
            <Cancel />
          </button>
          <ActivateMemberFailureModal
            setFormData={setFormData}
            setNextStep={setNextStep}
            formData={formData}
            patientData={patientData}
            partner={partner}
            error={activateErrorMsg}
            loading={loadingActiveMember}
          />
        </Drawer>
      )}

      {!isResponsiveView && isOpenActivateMemberModal && (
        <ModalLayout className={styles.modal} handleClose={handleClose}>
          <ActivateMemberFailureModal
            setFormData={setFormData}
            setNextStep={setNextStep}
            formData={formData}
            patientData={patientData}
            partner={partner}
            error={activateErrorMsg}
            loading={loadingActiveMember}
          />
        </ModalLayout>
      )}
    </>
  );
};

export default OnboardingFormSectionMedicalPersonal;
