import { useEffect, useRef } from "react";
import styles from "./TopmostBanner.module.scss";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";

interface IProps {
  isContentBanner?: boolean;
  contentBanner?: { text: string; link: string };
}

export const TopmostBanner = ({ contentBanner, isContentBanner }: IProps) => {
  const ref = useRef<any>(null);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  useEffect(() => {
    if (ref.current && isContentBanner) {
      const clientHeight = ref.current.clientHeight;
      if (isResponsiveView) document.getElementById("root")!.style.paddingTop = `${clientHeight}px`;
    }
  }, [isContentBanner]);

  if (isContentBanner) {
    return (
      <a
        href={contentBanner?.link}
        target="_blank"
        className={styles.root}
        ref={ref}
        rel="noreferrer"
      >
        <p className={styles.text}>{contentBanner?.text}</p>
      </a>
    );
  }

  return (
    <div className={styles.root}>
      <p className={styles.text}>
        Questions about how Neura Health works?{" "}
        <a href="https://www.neurahealth.co/enrollment" className={styles.link}>
          Book a 15 Minute Intro Call
        </a>
      </p>
    </div>
  );
};
