import { useQuery } from "@tanstack/react-query";
import firebase from "firebase/compat/app";
import { conditions } from "../../utils/conditionFocus";

export type PartnerData = {
  coachingOnly?: boolean;
  conditions?: conditions[];
  forceInsuranceBeforeBooking?: boolean;
  insuranceUILaunchControl?: Partial<Record<conditions, string[]>>;
  navigateDirectlyToBooking?: boolean;
  onboardingRequireAttribution?: boolean;
  onboardingRequireMemberID?: boolean;
  payors?: any[];
  showCreditCardUIBeforeBooking?: boolean;
  showInsuranceUIBeforeBooking?: boolean;
  stateList?: string[];
};

const key = "enterprise_client";

export const useGetPartnerData = (partnerName?: string, forceDisable?: boolean) => {
  const query = useQuery({
    queryKey: [key, partnerName],
    queryFn: partnerName
      ? async () => {
          const docRef = firebase.firestore().collection(key).doc(partnerName);
          const response = await docRef.get();
          return response.data() as PartnerData;
        }
      : undefined,
    enabled: !!partnerName && !forceDisable,
  });

  return query;
};
