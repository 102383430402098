import { useHistory } from "react-router-dom";
import sessionCheck from "../../../helpers/sessionCheck";
import { SetNewPasswordForm } from "../../SetNewPasswordForm/SetNewPasswordForm";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import firebase from "firebase/compat/app";
import amplitude from "amplitude-js";

import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from "firebase/auth";
import { useState } from "react";
import Modal from "../../Modal/Modal";
import OnboardingFormDescription from "../OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import Button from "../../Button/Button";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import { useGATrackingForSpecificPartner } from "../../../hooks/useGATrackingForSpecificPartner";
import { partnersName } from "../../../utils/partnerList";

interface IOnboardingFormSection2 extends IOnboardingFormSection {
  localGenPw: any;
}

export const OnboardingFormCreatePassword = ({
  formData,
  userEmail,
  setLoginMessage,
  step,
  setNextStep,
  setFirstStep,
  uid,
  partner,
  localGenPw,
}: IOnboardingFormSection2) => {
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const history = useHistory();
  const firestore = new FirebaseApi().firebaseFirestore();
  const functions = new FirebaseApi().firebaseFunctions();

  const { runTracking } = useGATrackingForSpecificPartner();

  function logOut() {
    amplitude.getInstance().setUserId(null);
    firebase.auth().signOut();
    history.push({
      pathname: "/welcome-back",
      search: window.location.search,
    });
    window.location.reload();
  }

  const onSubmit = async (value: { password: string }) => {
    if (!uid) return;
    const session = await sessionCheck(step, setFirstStep, setLoginMessage, history);
    if (!session) return;
    amplitude.getInstance().logEvent("onboarding_v2_password_created");

    // This event is for one specific partner, Theranica
    runTracking(partnersName.THERANICA, "neura_password-created");

    isNotExcludedFromTracking(formData?.email || userEmail, () => {
      // @ts-ignore
      window?.dataLayer?.push({
        event: "onboarding_v2_password_created",
      });
    });

    // Re-authenticate the user before attempting to update password
    // It's possible that the user refreshed the page, in which case the localGenPw will be null.
    // TODO: Log the user out in that case because they will not be able to
    // proceed. We will need to re-direct them to "Welcome Back, let's set your password"
    const emailForAuth = formData?.email || userEmail;
    if (localGenPw !== null && emailForAuth) {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        const credential = EmailAuthProvider.credential(emailForAuth, localGenPw);
        await reauthenticateWithCredential(currentUser, credential);
      }
    }
    session
      .updatePassword(value.password)
      .then(() => {
        firestore.updatePatient({
          data: {
            uid,
            defaultPasswordUpdated: true,
          },
        });

        setNextStep();

        functions.updateHubspotContact("default_password_updated", {
          email: formData?.email || userEmail,
          eventValue: true,
        });
      })
      .catch((err) => {
        if (err.code === "auth/requires-recent-login") {
          setOpenErrorModal(true);
        }
      });
  };

  const passwordSubtitle = partner
    ? "After that you’ll be able to book a visit with your medical team."
    : "After this, you'll be able to start the first week of membership for just $1.";
  return (
    <>
      <SetNewPasswordForm
        title="Create a Password"
        subTitle={passwordSubtitle}
        labelButton="Next"
        onSubmit={onSubmit}
      />

      {openErrorModal && (
        <Modal>
          <OnboardingFormDescription className="mb-3" text="Please log in again." />
          <div className="d-flex justify-content-center">
            <Button tag="button" title="OK" onClick={logOut} />
          </div>
        </Modal>
      )}
    </>
  );
};
