import cn from "classnames";
import { Helmet } from "react-helmet";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import OnboardingFormStepper from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormStepper/OnboardingFormStepper";
import { ReactComponent as IconSpin } from "../../assets/icons/spinner.svg";
import { ReactComponent as Logo } from "../../assets/images/svg/logo.svg";
import { ReactComponent as LogoMobile } from "../../assets/images/svg/logo-header-mobile.svg";
import { ReactComponent as IconBack } from "../../assets/icons/back.svg";
import { IOnboardingForm } from "../../components/OnboardingForm/OnboardingForm";
import styles from "./OnboardingLayout.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DoctorTelehealthImage from "../../assets/images/Doctor-Telehealth.png";
import { ReactComponent as BackgroundIcon } from "../../assets/images/svg/onboarding-phone-background-icon.svg";
import { TopmostBanner } from "../../components/OnboardingForm/OnboardingFormComponents/TopmostBanner/TopmostBanner";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { useOnboardingStepCountContext } from "../../context/onboardingStepCount";
import { IPatient } from "../../types/patient";

interface Props {
  children: React.ReactNode;
  uid?: IOnboardingForm["uid"];
  patientData?: IPatient | null;
  step?: number;
  setStep?: (step: IOnboardingForm["step"]) => void;
  setLoading?: (loading: boolean) => void;
  loading?: boolean;
  partner?: any;
  pathname?: string;
  modalOpen?: boolean;
  visibleLogo?: boolean;
}

export const OnboardingLayout = ({
  children,
  setStep,
  step,
  loading,
  partner,
  pathname,
  modalOpen,
  visibleLogo,
}: Props) => {
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const { stepCount } = useOnboardingStepCountContext();
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Neura Health</title>
      </Helmet>
      {!partner && pathname === "/onboarding" && <TopmostBanner />}
      <div
        style={!partner && pathname === "/onboarding" ? { paddingTop: "38px" } : {}}
        className={cn(styles.element, "d-flex flex-column", {
          [styles["element-fix"]]: step === 4,
          [styles.paymentsMinHeight]: smallScreen && step === 4,
          [styles.noPadding]: !isResponsiveView && step === stepCount,
          [styles.modalOpen]: modalOpen,
        })}
      >
        <div className={styles.assets}>
          {step === stepCount && <div className={styles.rainbow} />}
        </div>
        <div className={styles.header}>
          <div className={cn("d-flex w-100 align-items-center", styles.headerInner)}>
            {/* don't show logo for partner step 0, visibleLogo always overwrites */}
            {(!(partner && step === 0) || visibleLogo) && (
              <a
                className={cn(styles.logo, "d-inline-flex")}
                href={`https://neurahealth.co${window.location.search}`}
              >
                {isResponsiveView ? <LogoMobile /> : <Logo />}
              </a>
            )}
            {isResponsiveView && step && ![0, 1, 5].includes(step) ? (
              <button
                className={cn(styles.stepperBackButton)}
                onClick={() => {
                  if (step === 0) {
                    return;
                  }
                  setStep && step > 0 ? setStep(step - 1) : history.push("/");
                }}
              >
                <IconBack />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className={cn(styles.contentWrapper, {
            [styles.marginBottomForPayments]: step === 4,
          })}
        >
          <div
            className={cn(styles.content, {
              [styles.noMarginBottom]: !isResponsiveView && step === stepCount,
              [styles.posRelative]: step === 2,
            })}
          >
            <div className={styles.wrapper}>
              <div className={cn({ hidden: loading })}>{children}</div>

              <div
                className={cn(styles.wrapperLoading, "d-flex flex-column h-100", {
                  hidden: !loading,
                })}
              >
                <BackgroundIcon className={styles.backgroundIcon} />
                <BackgroundIcon className={styles.backgroundIcon2} />
                <LazyLoadImage alt="doctor" effect="blur" src={DoctorTelehealthImage} />
                <div className={styles.loader}>
                  <IconSpin />
                </div>
              </div>
            </div>
          </div>
          {!isResponsiveView &&
            step &&
            !(pathname === "/login" || step === 0) &&
            pathname !== "/reset-password" &&
            step <= stepCount + 1 &&
            !loading && (
              <OnboardingFormStepper
                isResponsiveView={isResponsiveView}
                step={step}
                length={stepCount}
                backAction={() => {
                  step && setStep && step > 1 ? setStep(step - 1) : history.push("/");
                }}
              />
            )}
        </div>
      </div>
    </>
  );
};
