import { Field, Form, Formik } from "formik";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import styles from "./InsuranceInfoModal.module.scss";

import * as yup from "yup";
import { useEffect, useState } from "react";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import OnboardingFormSelect from "../../OnboardingForm/OnboardingFormComponents/OnboardingFormSelect/OnboardingFormSelect";
import { ReactComponent as IconSpin } from "../../../assets/icons/spinner.svg";
import { PartnerData } from "../../../api/Queries/useGetPartnerData";

type FormValues = {
  payorFirestoreID: string;
  memberID: string;
};

const insuranceSchema = yup.object({
  memberID: yup.string().required("Member ID is required"),
  payorFirestoreID: yup.string().required("Insurance carrier is required"),
});

const initialValues: FormValues = {
  payorFirestoreID: "",
  memberID: "",
};

type Payor = {
  label: string;
  value: string;
};

type InsuranceInfoModalProps = {
  patientID: string;
  patientData?: any;
  partnerData?: PartnerData;
  handleClose: () => void;
  handleProceed: () => void;
};

const OTHER_KEY = "other";

export const InsuranceInfoModal = ({
  patientID,
  patientData,
  partnerData,
  handleClose,
  handleProceed,
}: InsuranceInfoModalProps) => {
  const [payors, setPayors] = useState<Payor[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleSubmit = async (values: FormValues) => {
    const functions = new FirebaseApi().firebaseFunctions();
    setLoading(true);
    const eligibility = await functions.checkEligibility({ ...values, patientID });
    if (eligibility.success) {
      handleProceed();
    } else {
      setError(
        eligibility.reason ||
          "We could not verify your insurance. Please contact us at support@neurahealth.co for assistance"
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    //@ts-ignore
    Kustomer.start({ brandId: "61b91090a1a78441a08b05a8" });
  }, []);

  const handleStartMessage = () => {
    handleClose();
    //@ts-ignore
    Kustomer.createConversation();
  };

  useEffect(() => {
    const firestore = new FirebaseApi().firebaseFirestore();
    const getPayors = async () => {
      const insurances = await firestore.getInsuranceLaunchControls();
      const partnerPayors = partnerData?.payors || [];
      const insurancePayors = Object.entries(insurances.livePayors);
      const filteredPayors: Record<string, string> = {};
      for (const [value, label] of insurancePayors) {
        if (partnerPayors.length === 0 || partnerPayors.includes(value)) {
          filteredPayors[value] = label;
        }
      }
      const payorOptions = Object.entries(filteredPayors)
        .map(([value, label]) => ({ label, value }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setPayors(
        patientData?.partnerName
          ? payorOptions
          : [...payorOptions, { label: "Other / Not Listed", value: OTHER_KEY }]
      );
    };
    getPayors();
  }, [partnerData, patientData?.partnerName]);

  const forceInsurance = partnerData?.forceInsuranceBeforeBooking;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={insuranceSchema}
    >
      {({ values, errors, handleChange, touched, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className={styles.form}>
          <h2 className={styles.title}>Insurance Information</h2>
          <h3 className={styles.subtitle}>
            Before booking, please enter your insurance information, so we can match you with an
            in-network provider
          </h3>
          <Field
            name="payorFirestoreID"
            label="Insurance Carrier"
            component={OnboardingFormSelect}
            options={payors}
            onChange={handleChange}
            className={styles.formField}
            error={!!errors.payorFirestoreID && touched.payorFirestoreID}
          />
          <Field
            name="memberID"
            label="Member ID"
            component={Input}
            value={values.memberID}
            fullWidth
            onChange={handleChange}
            className={styles.formField}
            error={!!errors.memberID && touched.memberID}
          />
          <div className={styles.errorContainer}>
            {values.payorFirestoreID === OTHER_KEY
              ? "Neura Health is not yet in-network with your insurance. You can still see a Neura provider by paying cash. Neura can provide you with a superbill to submit to your insurance for potential out-of-network reimbursement."
              : error}
          </div>
          <Button
            className={styles.formButton}
            type="submit"
            disabled={isSubmitting || values.payorFirestoreID === OTHER_KEY}
            shape="squared"
          >
            {loading ? <IconSpin /> : "Verify insurance"}
          </Button>
          <Button
            className={styles.formButton}
            type="button"
            variant="secondary"
            shape="squared"
            onClick={forceInsurance ? handleStartMessage : handleProceed}
          >
            {forceInsurance ? "Message us to book" : "Proceed without insurance"}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
