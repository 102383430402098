import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "@react-firebase/firestore";
import { conditions } from "../../utils/conditionFocus";

import { IPatient } from "../../types/patient";

import {
  CARETEAM_ROLE_SPECIALIST,
  CARETEAM_ROLE_NEUROLOGIST,
  CARETEAM_ROLE_PRIMARY_CARE,
  CARETEAM_ROLE_NURSE,
  CARETEAM_ROLE_CONCIERGE,
} from "../../helpers/constants";
import { IProvider } from "../../types/provider";

export type PostedPatientDataT = {
  uid?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  zipCode?: string;
  birthDate?: string;
  state?: string;
  phoneNumber?: string;
  stateForCare?: string;
  consentFormsSigned?: Array<{
    dateSigned: string;
    versionSigned: string;
  }>;
  planName?: string;
  consentedToSMSMarketing?: boolean;
  conditionFocus?: conditions[];
  primaryConditionFocus?: string;
  defaultPasswordUpdated?: boolean;
  b2bReferrer?: string;
  b2bReferrerPhone?: string;
};

export type ReceivedPatientDataT = {
  uid?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  stripeID?: string;
  subscriptionStatus?: string;
  lastSubscriptionCancellationDate?: any;
  isTestCustomer?: boolean;
  careTeam: [string];
  phoneNumber?: string;
  consentFormsSigned: Array<{
    dateSigned: string;
    versionSigned: string;
  }>;
  planName?: string;
  conditionFocus?: conditions[];
  consentedToSMSMarketing?: boolean;
};

export type AvailableStatesListT = {
  stateList: [string];
  livePayors: object;
};

type createPatientT = {
  email: string;
  uid: string;
};

type createPatientPartnerT = {
  email: string;
  uid: string;
  partnerName: string;
};

type updatePatientT = {
  data: PostedPatientDataT;
};

interface IFirebaseFirestore {
  getPatientData(uid: string): Promise<IPatient>;
  getPrescriptionAvailableStates(conditionArr: string[]): Promise<string[]>;
  createPatient(data: createPatientT): void;
  updatePatient(data: updatePatientT): void;
}

class FirebaseFirestore implements IFirebaseFirestore {
  private firestore: firebase.firestore.Firestore;

  constructor() {
    this.firestore = firebase.firestore();
  }

  async getProviderPhotoUrl(transparentImageFileName: string) {
    const teamMemberUrl = await firebase
      .storage()
      .refFromURL(
        "gs://gezunt-c3bfe.appspot.com/doctors/" + transparentImageFileName // ---> this is the name of the image file. This function constructs a CDN link and returns that.
      )
      .getDownloadURL();

    return teamMemberUrl;
  }

  async getCareTeam(uid: string) {
    const patientData = await this.getPatientData(uid);

    // It looks like patientData.careTeam can be empty sometimes.
    // It's rare, but does happen. It's possible that there is a race condition where
    // we attempt to load the patient before the careTeam is set by the cloud function
    // that does that. This function is triggered early on in the sign up flow, so
    // it should have finished by the time we get to the care team step but it
    // looks like we cannot rely on that.
    // Let's fall back to showing just Tom in those rare cases.
    const careTeamIDs = patientData.careTeam ?? ["mz2yHuSQMrIOe7EvfpRm"];
    const careTeamArray: any = [];
    const team: any = [];

    const snapshots = await this.firestore
      .collection("care_team")
      .where(firebase.firestore.FieldPath.documentId(), "in", careTeamIDs)
      .get();

    snapshots.forEach((documentSnapshot) => {
      careTeamArray.push(
        documentSnapshot.data() // --- the 'name' field has the name of the provider/concierge
      );
    });

    await Promise.all(
      careTeamArray.map(async (careTeamMember: IProvider) => {
        const teamMemberUrl = this.getProviderPhotoUrl(careTeamArray.transparentImageFileName);

        team.push({
          name: careTeamMember.name,
          photoUrl: teamMemberUrl,
          role: careTeamMember.role,
        });
      })
    );

    return {
      doctor: team.find(
        (careTeamPortfolio: any) =>
          careTeamPortfolio["role"] === CARETEAM_ROLE_SPECIALIST ||
          careTeamPortfolio["role"] === CARETEAM_ROLE_NEUROLOGIST ||
          careTeamPortfolio["role"] === CARETEAM_ROLE_NURSE
      ),
      concierge: team.find(
        (careTeamPortfolio: any) => careTeamPortfolio["role"] === CARETEAM_ROLE_CONCIERGE
      ),
    };
  }

  fetchProviderById = async (id: string) => {
    let provider: IProvider | null = null;
    const snapshots = await this.firestore.collection("care_team").doc(id).get();

    provider = snapshots.data() as IProvider;

    const teamMemberUrl = await this.getProviderPhotoUrl(provider.transparentImageFileName);

    provider.photoUrl = teamMemberUrl;

    return provider;
  };

  createPatient({ email, uid }: createPatientT) {
    const docRef = this.firestore.collection("patients").doc(uid);

    docRef.set({
      email: email,
    });
  }

  createPatientWithPartner({ email, uid, partnerName }: createPatientPartnerT) {
    const docRef = this.firestore.collection("patients").doc(uid);

    docRef.set({
      email: email,
      partnerName: partnerName,
    });
  }

  async updatePatient({ data }: updatePatientT) {
    const { uid } = data;

    const docRef = await this.firestore.collection("patients").doc(uid);

    await docRef.update(data);
  }

  async getPrescriptionAvailableStates(conditionArr: string[]) {
    const query = await this.firestore
      .collection("care_team")
      .where("conditionFocus", "array-contains-any", conditionArr)
      .where("role", "in", [
        CARETEAM_ROLE_SPECIALIST,
        CARETEAM_ROLE_NEUROLOGIST,
        CARETEAM_ROLE_PRIMARY_CARE,
        CARETEAM_ROLE_NURSE,
      ])
      .get();

    const careTeam = query.docs.map((doc) => doc.data());
    const states: string[] = careTeam.map((careTeamMember) => careTeamMember.states).flat();
    const uniqueStates = Array.from(new Set(states));

    return uniqueStates;
  }

  async getInsuranceLaunchControls() {
    const docRef = this.firestore.collection("launch_controls").doc("insurance");

    const response = await docRef.get();

    return response.data() as AvailableStatesListT;
  }

  async getPatientData(uid: string) {
    const docRef = this.firestore.collection("patients").doc(uid);
    const response = await docRef.get();
    const data = await response.data();

    return data as IPatient;
  }
}

export default FirebaseFirestore;
