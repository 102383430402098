import Icon1 from "../assets/images/svg/onboardingSectionPayment/chat.svg";
import Icon2 from "../assets/images/svg/onboardingSectionPayment/meeting-camera.svg";
import Icon3 from "../assets/images/svg/onboardingSectionPayment/insurance-hands.svg";

const ONBOARDING_PAYMENT_DATA = [
  {
    name: "Unlimited 24/7 in-app chat with patient care team",
    image: Icon1,
  },
  {
    name: "Same or next-day video visits",
    image: Icon2,
    sup: "*",
  },
  {
    name: "Personalized treatment plan and dedicated care coach",
    image: Icon3,
  },
];

export default ONBOARDING_PAYMENT_DATA;
