export enum conditions {
  HEADACHE_MIGRAINE = "headache/migraine",
  SLEEP = "sleep",
  EPILEPSY = "epilepsy",
  CONCUSSION_TBI = "concussion/tbi",
  STROKE = "stroke",
  TREMOR = "tremor",
  MEMORY = "memory",
  OTHER_UNDIAGNOSED = "other/undiagnosed",
}

export const conditionFocusTitles = {
  [conditions.HEADACHE_MIGRAINE]: "Headache/Migraine",
  [conditions.SLEEP]: "Sleep",
  [conditions.EPILEPSY]: "Epilepsy",
  [conditions.CONCUSSION_TBI]: "Concussion/TBI",
  [conditions.STROKE]: "Stroke",
  [conditions.TREMOR]: "Tremor",
  [conditions.MEMORY]: "Memory",
  [conditions.OTHER_UNDIAGNOSED]: "Other/Undiagnosed",
};
